<template>
  <v-bottom-sheet v-model="editing" scrollable persistent>
    <v-card v-if="editing">
      <v-card-title>
        <span v-if="activeItem.id">
          Edit "<b v-if="activeItem.translations"> {{ activeItem.translations[0].title }}" </b>
          <b v-else>{{ activeItem.title }}</b>
        </span>
        <span v-else>Add new item</span>

        <v-spacer></v-spacer>

        <v-btn @click="editing = false" icon small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text style="min-height: 55vh; max-height: 80vh">
        <v-form>
          <v-container class="mt-2">
            <!--<v-text-field
              v-model.number="activeItem.title"
              dense
              label="Title"
              outlined
              required
              type="number"
            ></v-text-field>-->
            <div>
              <v-tabs v-model="activeTranslation">
                <v-tab v-for="(translation, i) in activeItem.translations" :key="i">
                  {{
                    availableLocales.find(locale => locale.value === translation.locale) &&
                      availableLocales.find(locale => locale.value === translation.locale).name
                  }}
                </v-tab>
                <v-select
                  ref="addTranslation"
                  @change="addTranslation"
                  :items="availableLocales | notIn(activeItem.translations)"
                  item-text="name"
                  item-value="value"
                  style="max-width: max-content"
                  placeholder="Add new translation"
                  flat
                  solo
                ></v-select>
              </v-tabs>

              <v-tabs-items v-model="activeTranslation" class="mt-4">
                <v-tab-item v-for="(translation, i) in activeItem.translations" :key="i">
                  <v-text-field
                    label="Title"
                    v-model="translation.title"
                    outlined
                    dense
                    required
                    class="mt-4"
                  ></v-text-field>

                  <vue-editor v-model="translation.body"></vue-editor>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="saveItem()" :loading="loading" color="accent" block>Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { makeCopy } from "@/util/helpers";
import { VueEditor } from "vue2-editor";
import Trainplanet from "@/util/trainplanet.api";

export default {
  name: "EditTos",
  props: {
    item: Object,
    tenants: Array
  },
  components: {
    VueEditor
  },
  inject: ["emptyTranslation", "availableLocales"],
  data: () => ({
    loading: false,
    activeTranslation: null,
    e2: "Texas",
    states: ["Alabama", "Alaska", "American Samoa", "Wisconsin", "Wyoming"],
    selectedTenant: ""
  }),
  computed: {
    activeItem() {
      return this.item;
    },
    editing: {
      get() {
        return Object.keys(this.activeItem).length !== 0;
      },
      set(doing) {
        if (!doing) {
          this.$emit("item:cleared");
        }
      }
    }
  },
  watch: {
    activeItem(value) {
      if (value.tenant) {
        this.selectedTenant = value.tenant.id;
      } else {
        this.selectedTenant = "";
      }
    }
  },
  filters: {
    notIn(locales, translations) {
      return locales.filter(
        locale => !translations.some(translation => translation.locale === locale.value)
      );
    }
  },
  methods: {
    addTranslation(locale) {
      const translation = makeCopy(this.emptyTranslation); // Make copy
      translation.locale = locale;
      this.activeItem.translations.push(translation);
      this.$refs.addTranslation.reset(); // Reset the select input
    },
    async saveItem() {
      let response;

      const { id, parentId, ...item } = this.activeItem;

      this.loading = true;

      const post_item = { ...item, tenant: { id: this.selectedTenant } };
      const tenantId = this.activeItem.tenantId;

      try {
        await Trainplanet.createTranslationForTermsOfService({
          tenantId,
          body: {
            translations: this.activeItem.translations
          }
        });
      } catch (error) {
        this.$store.dispatch("error", error.response.data.message);
      }

      this.$emit("item:created", response);

      this.editing = false;
      this.loading = false;
    }
  }
};
</script>
