var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.items,
      "loading": _vm.loading,
      "options": _vm.options,
      "footer-props": {
        'items-per-page-options': [10, 20, 50, 100]
      }
    },
    on: {
      "update:options": [_vm.handleOptions, function ($event) {
        _vm.options = $event;
      }]
    },
    scopedSlots: _vm._u([{
      key: `item.versions`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.versions.length) + " ")];
      }
    }, {
      key: `item.languages`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return _vm._l(item.versions.length > 0 ? item.versions[0].translations : [], function (translation, index) {
          return _c('v-chip', {
            key: translation.locale,
            staticClass: "font-weight-bold white--text cliporder mr-2 my-1",
            staticStyle: {
              "min-width": "60px",
              "font-size": "12px",
              "min-height": "27px",
              "text-transform": "capitalize"
            },
            attrs: {
              "x-small": "",
              "color": index % 2 === 0 ? 'third' : 'greeny'
            }
          }, [_vm._v(" " + _vm._s(translation.locale) + " ")]);
        });
      }
    }, {
      key: `item.actions`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-row', {
          attrs: {
            "justify": "end"
          }
        }, [_c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "elevation": "1",
            "color": "accent",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.viewVersions(item);
            }
          }
        }, [_vm._v(" Versions ")]), _c('v-btn', {
          attrs: {
            "elevation": "1",
            "color": "third",
            "dark": "",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.editItem(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-pencil")]), _vm._v(" Edit ")], 1)], 1)], 1)];
      }
    }], null, true)
  }), _c('EditTos', {
    attrs: {
      "item": _vm.activeItem
    },
    on: {
      "item:cleared": _vm.itemCleared,
      "item:created": _vm.itemCreated
    }
  }), _c('TosVersions', {
    attrs: {
      "item": _vm.activeItemForView
    },
    on: {
      "item:cleared": _vm.itemCleared
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }