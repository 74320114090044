<template>
  <div>
    <v-row class="mb-3">
      <v-col cols="12">
        <div class="d-flex align-center">
          <v-select
            v-model="selectedTenants"
            :items="tenants"
            :loading="loading"
            solo
            class="align-stretch"
            chips
            hide-details
            item-text="name"
            item-value="id"
            :menu-props="{ maxHeight: '400' }"
            label="Select tenants"
            multiple
            :persistent-hint="false">
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < visibleChipLength">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === visibleChipLength" class="grey--text text-caption">
                (+{{ selectedTenants.length - visibleChipLength }} more)
              </span>
            </template>
          </v-select>
        </div>
      </v-col>
    </v-row>
    <tos-table v-show="tosItems.length" @options="handleOptions" :items="tosItems" @item:created="handleItemCreated" />
  </div>
</template>

<script>
import TrainplanetApi from "@/util/trainplanet.api";
import TosTable from "./components/TosTable.vue";
import { debounce } from "@/util/helpers";
import { mapGetters } from "vuex";

export default {
  components: {
    TosTable,
  },
  data: () => ({
    tosItems: [],
    options: {},
    selectedTenants: [],
    loading: false,
    isWatchable: false,
    initialLoaded: false,
    screenWidth: window.innerWidth,
  }),
  async mounted() {
    await this.getTermsOfServices();
    window.addEventListener("resize", this.updateScreenWidth);
  },
  watch: {
    options: {
      deep: true,
      handler: debounce(function () {
        if (!this.isWatchable) {
          this.isWatchable = true;
          return;
        }
        this.getTermsOfServices();
      }, 500),
    },
    selectedTenants: {
      deep: true,
      handler: debounce(function () {
        if (this.tenants.length > 0) {
          this.getTermsOfServices();
        }
      }, 500),
    },
  },
  computed: {
    ...mapGetters({
      tenants: "auth/userTenants",
    }),
    visibleChipLength() {
      switch (true) {
        case this.screenWidth >= 1600:
          return 10;
        case this.screenWidth >= 1400:
          return 8;
        case this.screenWidth >= 1200:
          return 6;
        case this.screenWidth >= 992:
          return 4;
        default:
          return 2;
      }
    },
  },
  methods: {
    async getTermsOfServices() {
      this.loading = true;
      try {
        let params = {};
        if (this.selectedTenants.length > 0 && this.initialLoaded) {
          params.tenantIds = this.selectedTenants;
          const res = await TrainplanetApi.getTermsOfServices(params);
          this.tosItems = res;
        } else {
          if (this.initialLoaded) {
            this.tosItems = [];
            return;
          }
          const res = await TrainplanetApi.getTermsOfServices();
          this.tosItems = res;
          this.initialLoaded = true;
        }
      } catch (error) {
        await this.$store.dispatch("error", error);
      } finally {
        this.loading = false;
      }
    },
    handleOptions(option) {
      this.options = option;
    },
    handleItemCreated() {
      this.getTermsOfServices();
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>
