<template>
  <div v-if="editing">
    <v-dialog
      max-width="80%"
      v-model="editing"
      :scrollable="false"
      persistent
      @click:outside="$emit('item:cleared')"
    >
      <v-card>
        <v-toolbar flat color="titlebg" class="title" dark>
          <v-toolbar-title>Versions</v-toolbar-title>
        </v-toolbar>
        <v-tabs slider-color="white" vertical>
          <div class="sidemenu" style="border-right:1px solid #dedede;">
            <v-tab @click="model = 1" class="px-10" v-for="(version, i) in item.versions" :key="i">
              {{ version.created }}
            </v-tab>
          </div>

          <v-tab-item v-for="(version, i) in item.versions" :key="i">
            <v-container>
              <v-card flat>
                <v-tabs v-model="model">
                  <v-tab
                    v-for="(translation, i) in version.translations"
                    :key="i"
                    :href="`#tab-${i}`"
                  >
                    {{ translation.locale }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="model">
                  <v-tab-item
                    v-for="(translation, i) in version.translations"
                    :key="i"
                    :value="`tab-${i}`"
                  >
                    <v-card flat>
                      <v-container>
                        <h1 color="primary" class="mb-2" style="border-bottom:1px solid #dedede">
                          {{ translation.title }}
                        </h1>
                        <p v-html="translation.body"></p>
                      </v-container>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["versions", "item"],
  data: () => ({
    activeVersion: 0,
    model: 0
  }),
  computed: {
    editing: {
      get() {
        return Object.keys(this.item).length !== 0;
      },
      set(doing) {
        if (!doing) {
          this.$emit("item:cleared");
        }
      }
    }
  }
};
</script>

<style scoped>
.sidemenu {
  height: 880px;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #f37a62;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #f37a62;
}
.v-dialog:not(.v-bottom-sheet) {
  overflow: hidden !important;
  height: 950px !important;
}
.v-slide-group__content .v-tabs-bar__content {
  overflow-y: auto;
  height: 800px;
}
.v-window-item .v-window-item--active {
  overflow-y: scroll;
  height: 800px;
}
</style>
