var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    staticClass: "mb-3"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-select', {
    staticClass: "align-stretch",
    attrs: {
      "items": _vm.tenants,
      "loading": _vm.loading,
      "solo": "",
      "chips": "",
      "hide-details": "",
      "item-text": "name",
      "item-value": "id",
      "menu-props": {
        maxHeight: '400'
      },
      "label": "Select tenants",
      "multiple": "",
      "persistent-hint": false
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item,
          index = _ref.index;
        return [index < _vm.visibleChipLength ? _c('v-chip', [_c('span', [_vm._v(_vm._s(item.name))])]) : _vm._e(), index === _vm.visibleChipLength ? _c('span', {
          staticClass: "grey--text text-caption"
        }, [_vm._v(" (+" + _vm._s(_vm.selectedTenants.length - _vm.visibleChipLength) + " more) ")]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.selectedTenants,
      callback: function ($$v) {
        _vm.selectedTenants = $$v;
      },
      expression: "selectedTenants"
    }
  })], 1)])], 1), _c('tos-table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tosItems.length,
      expression: "tosItems.length"
    }],
    attrs: {
      "items": _vm.tosItems
    },
    on: {
      "options": _vm.handleOptions,
      "item:created": _vm.handleItemCreated
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }