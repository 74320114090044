<template>
  <div>
    <v-data-table
      @update:options="handleOptions"
      :headers="headers"
      :items="items"
      :loading="loading"
      class="elevation-1"
      :options.sync="options"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }">
      <template v-slot:[`item.versions`]="{ item }">
        {{ item.versions.length }}
      </template>
      <template v-slot:[`item.languages`]="{ item }">
        <v-chip
          v-for="(translation, index) in item.versions.length > 0 ? item.versions[0].translations : []"
          x-small
          :key="translation.locale"
          :color="index % 2 === 0 ? 'third' : 'greeny'"
          class="font-weight-bold white--text cliporder mr-2 my-1"
          style="min-width: 60px; font-size: 12px; min-height: 27px; text-transform: capitalize">
          {{ translation.locale }}
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-row justify="end">
          <v-col md="auto">
            <v-btn @click="viewVersions(item)" class="mr-2" elevation="1" color="accent" small> Versions </v-btn>
            <v-btn @click="editItem(item)" elevation="1" color="third" dark small>
              <v-icon left>mdi-pencil</v-icon>
              Edit
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <EditTos :item="activeItem" @item:cleared="itemCleared" @item:created="itemCreated" />
    <TosVersions :item="activeItemForView" @item:cleared="itemCleared" />
  </div>
</template>

<script>
import { makeCopy } from "@/util/helpers";
import EditTos from "./EditTos.vue";
import TosVersions from "./TosVersions.vue";

export default {
  props: ["items", "loading"],
  components: { EditTos, TosVersions },
  data: () => ({
    emptyItem: {
      translations: [],
    },
    activeItem: {},
    options: {},
    activeItemForView: {},
    availableLocales: [
      {
        value: "sv-SE",
        name: "Swedish",
      },
      {
        value: "en-SE",
        name: "English",
      },
      {
        value: "nb-NO",
        name: "Norwegian",
      },
    ],
    emptyTranslation: {
      locale: "en-SE",
      title: null,
      body: null,
    },
    headers: [
      {
        text: "Tenant",
        value: "tenant.name",
      },
      {
        text: "Versions",
        value: "versions",
      },
      {
        text: "Languages",
        value: "languages",
      },
      {
        text: "",
        value: "actions",
        align: "right",
      },
    ],
  }),
  provide() {
    return {
      emptyTranslation: this.emptyTranslation,
      availableLocales: this.availableLocales,
    };
  },
  methods: {
    editItem(item) {
      const temp = { ...this.activeItem };
      const translation = makeCopy(this.emptyTranslation);

      temp.tenantId = item.tenant.id;
      temp.translations = item.versions.length > 0 ? item.versions[0].translations : [translation];
      this.activeItem = makeCopy(temp);
    },
    viewVersions(item) {
      const temp = { ...item };
      this.activeItemForView = makeCopy(temp);
    },
    itemCleared() {
      this.activeItem = {};
      this.activeItemForView = {};
    },
    itemCreated() {
      this.$emit("item:created");
    },
    handleOptions() {
      this.$emit("options", this.options);
    },
  },
};
</script>
