var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-bottom-sheet', {
    attrs: {
      "scrollable": "",
      "persistent": ""
    },
    model: {
      value: _vm.editing,
      callback: function ($$v) {
        _vm.editing = $$v;
      },
      expression: "editing"
    }
  }, [_vm.editing ? _c('v-card', [_c('v-card-title', [_vm.activeItem.id ? _c('span', [_vm._v(" Edit \""), _vm.activeItem.translations ? _c('b', [_vm._v(" " + _vm._s(_vm.activeItem.translations[0].title) + "\" ")]) : _c('b', [_vm._v(_vm._s(_vm.activeItem.title))])]) : _c('span', [_vm._v("Add new item")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.editing = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticStyle: {
      "min-height": "55vh",
      "max-height": "80vh"
    }
  }, [_c('v-form', [_c('v-container', {
    staticClass: "mt-2"
  }, [_c('div', [_c('v-tabs', {
    model: {
      value: _vm.activeTranslation,
      callback: function ($$v) {
        _vm.activeTranslation = $$v;
      },
      expression: "activeTranslation"
    }
  }, [_vm._l(_vm.activeItem.translations, function (translation, i) {
    return _c('v-tab', {
      key: i
    }, [_vm._v(" " + _vm._s(_vm.availableLocales.find(function (locale) {
      return locale.value === translation.locale;
    }) && _vm.availableLocales.find(function (locale) {
      return locale.value === translation.locale;
    }).name) + " ")]);
  }), _c('v-select', {
    ref: "addTranslation",
    staticStyle: {
      "max-width": "max-content"
    },
    attrs: {
      "items": _vm._f("notIn")(_vm.availableLocales, _vm.activeItem.translations),
      "item-text": "name",
      "item-value": "value",
      "placeholder": "Add new translation",
      "flat": "",
      "solo": ""
    },
    on: {
      "change": _vm.addTranslation
    }
  })], 2), _c('v-tabs-items', {
    staticClass: "mt-4",
    model: {
      value: _vm.activeTranslation,
      callback: function ($$v) {
        _vm.activeTranslation = $$v;
      },
      expression: "activeTranslation"
    }
  }, _vm._l(_vm.activeItem.translations, function (translation, i) {
    return _c('v-tab-item', {
      key: i
    }, [_c('v-text-field', {
      staticClass: "mt-4",
      attrs: {
        "label": "Title",
        "outlined": "",
        "dense": "",
        "required": ""
      },
      model: {
        value: translation.title,
        callback: function ($$v) {
          _vm.$set(translation, "title", $$v);
        },
        expression: "translation.title"
      }
    }), _c('vue-editor', {
      model: {
        value: translation.body,
        callback: function ($$v) {
          _vm.$set(translation, "body", $$v);
        },
        expression: "translation.body"
      }
    })], 1);
  }), 1)], 1)])], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "color": "accent",
      "block": ""
    },
    on: {
      "click": function ($event) {
        return _vm.saveItem();
      }
    }
  }, [_vm._v("Save")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }