var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.editing ? _c('div', [_c('v-dialog', {
    attrs: {
      "max-width": "80%",
      "scrollable": false,
      "persistent": ""
    },
    on: {
      "click:outside": function ($event) {
        return _vm.$emit('item:cleared');
      }
    },
    model: {
      value: _vm.editing,
      callback: function ($$v) {
        _vm.editing = $$v;
      },
      expression: "editing"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "flat": "",
      "color": "titlebg",
      "dark": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("Versions")])], 1), _c('v-tabs', {
    attrs: {
      "slider-color": "white",
      "vertical": ""
    }
  }, [_c('div', {
    staticClass: "sidemenu",
    staticStyle: {
      "border-right": "1px solid #dedede"
    }
  }, _vm._l(_vm.item.versions, function (version, i) {
    return _c('v-tab', {
      key: i,
      staticClass: "px-10",
      on: {
        "click": function ($event) {
          _vm.model = 1;
        }
      }
    }, [_vm._v(" " + _vm._s(version.created) + " ")]);
  }), 1), _vm._l(_vm.item.versions, function (version, i) {
    return _c('v-tab-item', {
      key: i
    }, [_c('v-container', [_c('v-card', {
      attrs: {
        "flat": ""
      }
    }, [_c('v-tabs', {
      model: {
        value: _vm.model,
        callback: function ($$v) {
          _vm.model = $$v;
        },
        expression: "model"
      }
    }, _vm._l(version.translations, function (translation, i) {
      return _c('v-tab', {
        key: i,
        attrs: {
          "href": `#tab-${i}`
        }
      }, [_vm._v(" " + _vm._s(translation.locale) + " ")]);
    }), 1), _c('v-tabs-items', {
      model: {
        value: _vm.model,
        callback: function ($$v) {
          _vm.model = $$v;
        },
        expression: "model"
      }
    }, _vm._l(version.translations, function (translation, i) {
      return _c('v-tab-item', {
        key: i,
        attrs: {
          "value": `tab-${i}`
        }
      }, [_c('v-card', {
        attrs: {
          "flat": ""
        }
      }, [_c('v-container', [_c('h1', {
        staticClass: "mb-2",
        staticStyle: {
          "border-bottom": "1px solid #dedede"
        },
        attrs: {
          "color": "primary"
        }
      }, [_vm._v(" " + _vm._s(translation.title) + " ")]), _c('p', {
        domProps: {
          "innerHTML": _vm._s(translation.body)
        }
      })])], 1)], 1);
    }), 1)], 1)], 1)], 1);
  })], 2)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }